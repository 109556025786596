import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledGiveAdoption = styled.div`
  text-align: center;
`;

export const StyledGiveAdoptionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: ${Rem(940)};
  margin: 0 auto;
  padding-top: ${Rem(40)};

  @media (${BP.ipad}) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const StyledGiveAdoptionImage = styled.img`
  width: 100%;
  height: auto;
  min-width: ${Rem(166)};
  max-width: ${Rem(166)};
  margin-bottom: ${Rem(36)};

  @media (${BP.ipad}) {
    margin: 0 ${Rem(36)} 0 0;
  }
`;

export const StyledGiveAdoptionText = styled.div`
  font-size: ${Rem(18)};
  line-height: ${Rem(24)};
  font-weight: normal;

  @media (${BP.ipad}) {
    font-size: ${Rem(20)};
    line-height: ${Rem(32)};
    text-align: left;
  }
`;

export const StyledGiveAdoptionSubtitle = styled.div`
  padding-bottom: ${Rem(24)};
`;

export const StyledGiveAdoptionDescription = styled.div`
  text-align: left;
  color: ${Colors.gray};
  font-weight: 300;
`;
